import React from "react"
import FaculyList from "../components/FacultyList"
import PageHeadBanner from "../components/PageHeadBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"
const Faculties = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"Faculties"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Faculties"}
        subtitle={"List of Experienced Teaching Staff"}
      />
      <FaculyList title={null} />
    </>
  )
})

export default Faculties
