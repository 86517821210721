import React from "react"
import slugify from "slugify"

const faculties = [
  {
    name: "Dr. Garima Kalita",
    qualification: "MA, PhD",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dwijen Dutta",
    qualification: "MSc, MPhill",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Hari Shankar Kakati",
    qualification: "MSc, PhD",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Jogendra Kr. Das",
    qualification: "MA, PhD",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Phulrani Deka",
    qualification: "MA",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Jugal Baishya",
    qualification: "Double MA",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Swarup Sarma",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Iva Rani Mazumdar",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Jagadish Sarma",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Shivanee Kumari",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Paresh Singh",
    qualification: "MCom",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Anannya Kalita",
    qualification: "MSc, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Pranjal Das",
    qualification: "MSc",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Prasanta Kalita",
    qualification: "MSc, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Priyanka Das",
    qualification: "MSc",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Manabendra Kalita",
    qualification: "MSc, DFPT",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Bhimakshi Das",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Pari Kalita",
    qualification: "MBA, LLB",
    avatar: "/images/people/default_avatar.jpg",
  },
]

const FaculyList = ({ title = "Teaching and Non-Teaching Staff" }) => {
  return (
    <>
      <section className="team-area-three ptb-100">
        <div className="container">
          {title && (
            <div className="section-title">
              <h2>{title}</h2>
              <p>
                Experianced Faculties and College Staff of Darwin Academy,
                Mirza.
              </p>
            </div>
          )}
          <div className="row justify-content-md-center">
            {faculties.map(t => (
              <div
                key={slugify(t.name)}
                className="col-lg-3 col-md-4 col-sm-6 col-12"
              >
                <div className="single-team-member">
                  <img src={t.avatar} alt="Image" />
                  <div
                    className="team-content"
                    style={{ width: "100%", padding: 0 }}
                  >
                    <div
                      className="team-name"
                      style={{ width: "100%", padding: "15px 10px" }}
                    >
                      <h4 style={{ fontSize: 15 }}>{t.name}</h4>
                      <span style={{ fontSize: 11, minHeight: 40 }}>
                        ({t.qualification})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default FaculyList
